import React from 'react';
import SEO from '../Seo';
import Footer from './Footer';
import Header from './Header';

const Layout = ({ children }) => (
    <div style={{ background: 'rgba(245, 232, 198, 0.10)' }}>
        <SEO />
        <Header />
        <main className="text-gray-900 2xl:px-48 lg:px-32 px-4">{children}</main>
        <Footer />
    </div>
);

export default Layout;
