import React from 'react';
import GalleryItem from './GalleryItem';
import '../css/index.css';
import '../css/Gallery.css';
import Carousel, { Modal, ModalGateway } from 'react-images';
class Gallery extends React.Component {
    state = {
        selectedIndex: 0,
        lightboxIsOpen: false,
    };

    toggleLightbox = (selectedIndex) => {
        this.setState((state) => ({
            lightboxIsOpen: !state.lightboxIsOpen,
            selectedIndex,
        }));
    };

    render() {
        let { isLoading, images, thumbnails } = this.props;
        images = images || [];
        const { lightboxIsOpen } = this.state;
        return (
            <div className="gallery-container">
                {getGalletyItems(images, thumbnails).map(({ src, caption }, index) => (
                    <GalleryItem
                        src={src}
                        caption={caption}
                        toggleLightbox={() => this.toggleLightbox(index)}
                        key={index}
                        index={index}
                    />
                ))}
                <ModalGateway>
                    {lightboxIsOpen && !isLoading ? (
                        <Modal onClose={this.toggleLightbox}>
                            <Carousel views={images} currentIndex={this.state.selectedIndex} />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        );
    }
}

function getGalletyItems(items, thumbnails) {
    if (thumbnails?.length) return thumbnails;
    return items;
}

export default Gallery;
