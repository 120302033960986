export default [
    {
        title: 'Doskonała lokalizacja',
        content: `Dom wypoczynkowy Pod Dębem położony jest w spokojnej części Rowów zaledwie 200m od centrum, 300 metrów do plaży.`,
        icon: 'FaMapMarker',
        name: 'localization',
    },
    {
        title: 'Parking dla gości',
        content: 'Posiadamy bezpłatny parking dla gości na terenie naszego obiektu.',
        icon: 'FaParking',
        name: 'parking',
    },
    {
        title: 'Mini atrakcje dla dzieci',
        content: 'Dla naszych najmłodszych gości posiadamy atrakcje w postaci mini placu zabaw oraz trampoliny.',
        icon: 'FaGrinStars',
        name: 'kids',
    },
    {
        title: 'WiFi',
        content: 'Na terenie obiektu oferujemy bezpłatny dostęp do Internetu',
        icon: 'FaWifi',
        name: 'wifi',
    },
    {
        title: 'Miejsce na rowery',
        content: 'Do Państwa dyspozycji oddajemy miejsce do przechowania rowerów',
        icon: 'FaBicycle',
        name: 'bikes',
    },
];
