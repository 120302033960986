import React from 'react';
import '../css/GalleryItem.css';

const GalleryItem = (props) => {
    const { src, caption, index, toggleLightbox } = props;
    return (
        <>
            <div className="gallery-item" onClick={() => toggleLightbox(index)} key={src}>
                <div className="gallery-item-img">
                    <img loading="lazy" alt={caption} src={src} />
                </div>
            </div>
        </>
    );
};
export default GalleryItem;
