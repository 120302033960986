const MENU_ITEMS = [
    { title: 'Start', href: '#start' },
    { title: 'Oferta', href: '#information' },
    { title: 'Galeria', href: '#gallery' },
    { title: 'Cennik', href: '#pricing' },
    { title: 'Lokalizacja', href: '#localization' },
    { title: 'Kontakt', href: '#localization' },
];

export default MENU_ITEMS;
