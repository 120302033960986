import React from 'react';
import CustomerCard from '../components/CustomerCard';
import Gallery from '../components/Gallery';
import Icon from '../components/Icon';
import Layout from '../components/layout/Layout';
import MyMap from '../components/MyMap';
import PricingTable from '../components/PricingTable';
import SplitSection from '../components/SplitSection';
import '../css/index.css';
import cardsData from '../data/cards-data';
import { apartmentImages, propertyImages, roomsImages } from '../data/images';

const createThumbnailsList = (images) => {
    return images.map((el) => ({
        ...el,
        src: el.src.replace('images', 'thumbnails').replace('.jpg', '_thumbnail.jpg'),
    }));
};

const MainPage = () => (
    <Layout>
        <section id="start" className="pt-10 md:pt-10">
            <div className="container mx-auto lg:flex">
                <div className="text-center lg:text-left lg:w-1/2">
                    <h1 className="text-2xl lg:text-4xl xl:text-6xl font-bold leading-none lg:mb-16">
                        Zapraszamy Pokoje i Apartamenty Pod Dębem
                    </h1>
                    <p className="text-xl lg:text-2xl mt-6 mb-6 font-light lg:pr-8 text-left">
                        To miejsce, w którym każdy znajdzie ciszę i spokój, jakiego potrzebuje. Szum morza, zapach lasu
                        oraz piękne widoki nie wymagają dalekich wędrówek, wszystkie atrakcje są osiągalne w zaledwie
                        kilka minut od naszego ośrodka. W Rowach oprócz malowniczych plaż mogą Państwo rozkoszować się
                        widokami jakie oferuję jezioro Gardno.
                    </p>
                </div>
                <div className="flex justify-center">
                    <img
                        src="../../images/main/start-panorama.jpg"
                        alt="start"
                        loading="lazy"
                        className="img-rounded max-w-2xl xs:hidden"
                    />
                </div>
            </div>
        </section>
        <div className="text-center md:pt-10">
            <h2 className=" mb-4 mt-4 text-3xl xs:text-2xl lg:text-5xl font-semibold">Oferta</h2>
        </div>

        <SplitSection
            secondarySlot={
                <div className="lg:pl-8 p-4 xs:p-1">
                    <h3 className="text-3xl xs:text-xl font-semibold">Apartamenty</h3>
                    <p className="mt-2 text-xl xs:text-base font-light md:leading-relaxed">
                        Przeznaczone dla 4-5 osób. Apartament składa się z sypialni, pokoju dziennego oraz przestronnej
                        łazienki. Wyposażenie:
                        <br />- łoże małżeńskie, rozkładana kanapa
                        <br />- mała kuchnia (czajnik, kuchenka elektryczna, mała lodówka)
                        <br />- tv
                    </p>
                </div>
            }
            primarySlot={
                <img alt="img_31" src="../../images/img_31.jpg" loading="lazy" className="img-rounded max-w-2xl" />
            }
        />
        <SplitSection
            id="information"
            reverseOrder
            secondarySlot={
                <div className="lg:pl-8 p-4 xs:p-1">
                    <h3 className="text-3xl xs:text-xl font-semibold">Pokoje</h3>
                    <p className="mt-2 text-xl xs:text-base font-light md:leading-relaxed">
                        Wygodne dobrze wyposażone dwuosobowe pokoje z łazienką. Wyposażenie pokoju:
                        <br />- jedno łoże małżeńskie lub dwa pojedyńcze
                        <br />- sprzęt plażowy (koc, parawan)
                        <br />- mała lodówka, czajnik
                        <br />- tv
                    </p>
                </div>
            }
            primarySlot={<img alt="img_6" src="../../images/img_6.jpg" className="img-rounded max-w-2xl" />}
        />
        <div className="container mx-auto text-center">
            <div className="flex flex-wrap flex-row py-4 lg:py-8 justify-around grow">
                {cardsData.map((card) => (
                    <CustomerCard key={card.name} card={card} />
                ))}
            </div>
        </div>

        <section id="gallery" className="">
            <div className="container mx-auto text-center">
                <h2 className=" mb-4 text-3xl xs:text-2xl lg:text-5xl font-semibold">Galeria</h2>
                <div className="shadow-lg p-4 lg:p-12  rounded-lg border border-solid border-gray-200 mb-8">
                    <h4 className="text-3xl mb-4 xs:text-xl font-semibold leading-tight">Obiekt</h4>
                    <Gallery images={propertyImages} thumbnails={createThumbnailsList(propertyImages)} />
                </div>
                <div className="shadow-lg p-4 lg:p-12  rounded-lg border border-solid border-gray-200 mb-8">
                    <h3 className="text-3xl mb-4 xs:text-2xl font-semibold leading-tight">Apartamenty</h3>
                    <Gallery images={apartmentImages} />
                </div>
                <div className="shadow-lg p-4 lg:p-12  rounded-lg border border-solid border-gray-200 mb-8">
                    <h3 className="text-3xl mb-4 xs:text-2xl font-semibold leading-tight">Pokoje</h3>
                    <Gallery images={roomsImages} />
                </div>
            </div>
        </section>

        <section id="pricing" className="">
            <div className="container mx-auto text-center flex flex-col gap-8">
                <h2 className="text-3xl lg:text-5xl font-semibold">Rezerwacje</h2>
                <p className="text-xl lg:text-2xl lg:text-center">
                    Serdecznie zapraszamy do rezerwacji pobytu telefonicznie pod numerem
                </p>
                <a className="hover:text-blue-600 font-bold " href="tel:609873649">
                    <div className="flex justify-center">
                        <Icon size="32px" iconName="FaMobileAlt" />
                        <p className="text-xl lg:text-2xl">609-873-649</p>
                    </div>
                </a>
                <div className="pb-8">
                    <PricingTable />
                </div>
            </div>
        </section>

        <section id="localization">
            <div className="text-center flex flex-col gap-4">
                <h2 className="text-3xl lg:text-5xl font-semibold">Lokalizacja</h2>
                <MyMap style={{ width: '100%', height: '400px' }} />
            </div>
        </section>
    </Layout>
);

export default MainPage;
