export const roomsImages = [
    { src: '../../images/img_1.jpg', id: 1 },
    { src: '../../images/img_2.jpg', id: 2 },
    { src: '../../images/img_3.jpg', id: 3 },
    { src: '../../images/img_4.jpg', id: 4 },
    { src: '../../images/img_5.jpg', id: 5 },
    { src: '../../images/img_6.jpg', id: 6 },
    { src: '../../images/img_8.jpg', id: 8 },
    { src: '../../images/img_9.jpg', id: 9 },
    { src: '../../images/img_10.jpg', id: 10 },
    { src: '../../images/img_11.jpg', id: 11 },
    { src: '../../images/img_42.jpg', id: 42 },
    { src: '../../images/img_43.jpg', id: 43 },
    { src: '../../images/img_36.jpg', id: 36 }, // pokoje
    { src: '../../images/img_30.jpg', id: 30 }, // pokoje
    { src: '../../images/img_41.jpg', id: 41 }, // pokoje
];

export const apartmentImages = [
    { src: '../../images/img_24.jpg', id: 24 },
    { src: '../../images/img_31.jpg', id: 31 },
    { src: '../../images/img_25.jpg', id: 25 },
    { src: '../../images/img_26.jpg', id: 26 },
    { src: '../../images/img_27.jpg', id: 27 },
    { src: '../../images/img_19.jpg', id: 19 },
    { src: '../../images/img_13.jpg', id: 13 },
    { src: '../../images/img_14.jpg', id: 14 },
    { src: '../../images/img_18.jpg', id: 18 },
    { src: '../../images/img_22.jpg', id: 22 },
    { src: '../../images/img_16.jpg', id: 16 },
    { src: '../../images/img_21.jpg', id: 21 },
    { src: '../../images/img_17.jpg', id: 17 },
    { src: '../../images/img_28.jpg', id: 28 },
    { src: '../../images/img_20.jpg', id: 20 }, // apartamenty
];

export const propertyImages = [
    { src: '../../images/img_40.jpg', id: 40 },
    { src: '../../images/img_33.jpg', id: 33 },
    { src: '../../images/img_7.jpg', id: 7 },
    { src: '../../images/img_35.jpg', id: 35 },
    { src: '../../images/img_38.jpg', id: 38 },
    { src: '../../images/img_39.jpg', id: 39 },
    { src: '../../images/img_34.jpg', id: 34 },
    { src: '../../images/img_32.jpg', id: 32 },
    { src: '../../images/img_29.jpg', id: 29 },
    { src: '../../images/img_37.jpg', id: 37 },
];
