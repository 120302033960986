import React from 'react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';

const MyMap = (props) => {
    const { options, style } = props;
    const position = [54.6652, 17.057];

    if (typeof window === 'undefined') {
        return null;
    }

    return (
        <Map {...options} style={style} center={position} zoom={16}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position}>
                <Popup>
                    <b>Pokoje i Apartamenty Gościnne pod Dębem</b>
                    <br />
                    Rowy, ul. Kościelna 15.
                    <br />
                    tel. 609-873-649
                </Popup>
            </Marker>
        </Map>
    );
};

export default MyMap;
