import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import MENU_ITEMS from '../../data/menu';
import MainLogo from '../MainLogo';
import MobileMenu from '../MobileMenu';

const Header = () => {
    return (
        <header
            className="top-0 shadow-sm font-sans 2xl:px-48 md:px-28 px-4"
            style={{ zIndex: 10000000, background: 'rgba(245, 232, 198, 0.15)' }}
        >
            <div className="flex justify-between items-center py-2">
                <MainLogo />
                <div className="md:hidden flex">
                    <MobileMenu />
                </div>
                <div className="hidden md:flex flex-row md:gap-4 lg:gap-8 justify-center text-xl text-center items-center">
                    {MENU_ITEMS.map((menuItem) => (
                        <AnchorLink key={menuItem.title} href={menuItem.href}>
                            {menuItem.title}
                        </AnchorLink>
                    ))}
                </div>
            </div>
        </header>
    );
};

export default Header;
