import React from 'react';

const Footer = () => (
    <footer className="py-5 pl-3 text-gray-800" style={{ background: 'rgba(245, 232, 198, 0.20)' }}>
        <div className="flex-1 flex flex-col lg:flex-row">
            <div className="flex-1 px-4  text-xl font-light lg:leading-relaxed">
                <h2 className="text-lg font-semibold">Kontakt:</h2>
                <p className="mt-5 xs:mt-1">Halina i Michał Letkowscy</p>

                <p>Rowy 76-212</p>
                <p>ul. Kościelna 15</p>
            </div>
            <div className="flex-1 px-4 py-4">
                <h2 className="text-2xl xs:text-base font-light leading-relaxed font-semibold">
                    Rezerwacje:{' '}
                    <a className="text-amber-800 hover:text-blue-600" href="tel:609873649">
                        tel. 609-873-649
                    </a>
                </h2>
                <h3 className="text-2xl xs:text-base font-light leading-relaxed font-semibold">
                    Konto: 84 1020 4649 0000 7002 0013 0559
                </h3>
            </div>
        </div>
        <div>
            <p className="text-center py-1 xs:text-xs"> Wszelkie prawa zastrzeżone rowypoddebem.pl &#169; 2024 </p>
        </div>
    </footer>
);

export default Footer;
