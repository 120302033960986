import React from 'react';

const PriceCard = ({ price, title }) => (
    <div
        style={{
            boxShadow: '0 10px 28px rgba(0,0,0,.08)',
        }}
        className="p-8 rounded-lg border border-solid border-gray-100 m-2"
    >
        <h3 className="text-2xl font-semibold">{title}</h3>
        <p className="text-center text-2xl font-bold">{price}</p>
    </div>
);

const PricingTable = () => {
    return (
        <>
            <p className="text-xl lg:text-2xl">
                Pokoje i apartamenty wynajmujemy w okresie <strong>od maja do września</strong>. Cena jest uzależniona
                od terminu Państwa pobytu oraz obłożenia ośrodka.
            </p>
            <div className="sm:flex justify-center mt-4">
                <PriceCard title="Pokoje 2-3 os." price="60 - 100 zł/osoba" />
                <PriceCard title="Apartamenty" price="250 - 450 zł/doba" />
            </div>
        </>
    );
};

export default PricingTable;
