import React from 'react';
import Icon from './Icon';

const CustomerCard = ({ card }) => (
    <div
        style={{
            boxShadow: '0 10px 28px rgba(0,0,0,.08)',
        }}
        className="p-4 rounded-lg border border-solid border-gray-200 m-1 xs:flex xs:items-center max-sm:w-full"
    >
        <div className="flex justify-center xs:mx-4" title={card.title}>
            <Icon size="32px" iconName={card.icon} />
        </div>
        <h4 className="text-xl xs:text-xs font-semibold xs:hidden">{card.title}</h4>
        <p className="mt-3 md:max-w-xs xs:text-xs">{card.content}</p>
    </div>
);

export default CustomerCard;
